import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Section from 'components/organisms/Section/Section';
import { DemoDataMap, VideoSection, Spaces } from './components';
import SEO from '../../components/SEO';
import { mapData } from './data';

export const Head = () => (
  <SEO description="Construct and run virtual field trips using your own digital outcrop models." />
);

const useStyles = makeStyles((theme) => ({
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    maxWidth: 800,
    margin: '0 auto',
  },
}));

const VirtualFieldTrips = () => {
  const classes = useStyles();

  // const [openBottombar, setOpenBottombar] = React.useState(false);

  // const handleBottombarOpen = () => {
  //   setOpenBottombar(true);
  // };

  // const handleBottombarClose = () => {
  //   setOpenBottombar(false);
  // };

  return (
    <Fragment>
      <Section className={classes.pagePaddingTop}>
        <VideoSection
          videoSrc="https://www.youtube.com/embed/AhDJF9cQlaw"
          videoTitle="Run rich virtual field trip experiences with VRGS"
          videoSubtitle="Digital outcrop models provide excellent training opportunities in both industry and academia. Make your field time more efficient with pre-trip familiarisation, or post-trip learning reinforcement. Even if you can't get into the field, digital outcrop models provide insights and learning beyond that achievable through traditional presentations."
          buttonName="30 Day Trial"
          buttonLink="/get-trial"
        />
      </Section>
      <DemoDataMap data={mapData} />
      <Section className={classes.pagePaddingTop}>
        <VideoSection
          videoSrc="https://www.youtube.com/embed/xdL7PGjRgCs"
          videoTitle="Use hyperlinks to navigate your models."
          videoSubtitle="The embedded browser allows you to link to your models from webpages, improving the training experience. You can even create your own virtual field trip guides using the integrated HTML editor and field guide template."
        />
      </Section>
      <Section>
        <Spaces />
      </Section>
    </Fragment>
  );
};

export default VirtualFieldTrips;
