import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';
import Map from 'components/organisms/Map/Map';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  map: {
    zIndex: 9,
  },
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  heroleftSide: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
    },
  },
}));

const DemoDataMap = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div className={classes.heroleftSide}>
            <SectionHeader
              title="Create virtual field trips"
              subtitle="Construct your virtual field tours in VRGS. Use them as pre and post realworld fieldtrip training to enhance the learning experience. Use the remote collaboration tools to run virtual field training across sites."
              data-aos="fade-up"
              align="left"
            />
          </div>
        }
        rightSide={
          <Map
            center={[53.700446, -2.301442]}
            pins={data}
            className={classes.map}
            zoom={6}
          />
        }
      />
    </div>
  );
};

DemoDataMap.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default DemoDataMap;
