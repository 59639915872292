import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
const useStyles = makeStyles((theme) => ({
  imageGrid: {
    padding: '.25rem',
    width: '150%',
    boxShadow: '0 1.5rem 4rem rgba(22,28,45,.1)',
    background: theme.palette.background.paper,
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
      borderStyle: 'none',
    },
  },
  imageGridFirstItem: {
    marginTop: '4rem',
  },
  imageGridLastItem: {
    marginTop: '6rem',
    float: 'right',
  },
}));

const Spaces = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SectionHeader
            label="SCALABLE"
            title={
              <span>
                Committed to developing the{' '}
                <Typography
                  color="secondary"
                  variant="inherit"
                  component="span"
                >
                  best
                </Typography>{' '}
                tools for geoscientists
              </span>
            }
            subtitle="Build your Virtual Field Tours with the data you have available, whether its photogrammetric, lidar or DEM data. If your data is in a format which VRGS can't read then let us know and we will see if we can add an import filter for it."
            align="left"
            fadeUp
            disableGutter
          />
        </Grid>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <Grid
            container
            className={clsx('overlaped-images', className)}
            {...rest}
          >
            <Grid item xs={4} className="overlaped-images__item-container">
              <div
                className={clsx(
                  'overlaped-images__item-wrapper',
                  classes.imageGrid,
                  classes.imageGridFirstItem,
                )}
              >
                <StaticImage
                  src="images/background1.jpg"
                  alt="Digital outcrop modelling and Virtual geology from VRGeoscience"
                  className="overlaped-images__item-image"
                />
              </div>
            </Grid>
            <Grid item xs={4} className="overlaped-images__item-container">
              <div
                className={clsx(
                  'overlaped-images__item-wrapper',
                  classes.imageGrid,
                )}
              >
                <StaticImage
                  src="images/mudstones.jpg"
                  alt="Mudstones and fractures in VRGS"
                  className="overlaped-images__item-image"
                />
              </div>
            </Grid>
            <Grid item xs={4} className="overlaped-images__item-container">
              <div
                className={clsx(
                  'overlaped-images__item-wrapper',
                  classes.imageGrid,
                  classes.imageGridLastItem,
                )}
              >
                <StaticImage
                  src="images/geotour3d.jpg"
                  alt="Virtual Field Trips in GeoTour3D"
                  className="overlaped-images__item-image"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Spaces.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Spaces;
