export const advantages = [
  {
    icon: 'fas fa-users',
    title: 'Coworking communities',
    subtitle:
      'Connect in spaces designed to bring incredible people together. Learn with them and take your project to new heights.',
  },
  {
    icon: 'far fa-address-book',
    title: 'Flexible contracts',
    subtitle:
      'Stay as little as 3 months with rolling contracts. Like it here? This is your space, so stay as long as you want.',
  },
  {
    icon: 'fab fa-angellist',
    title: 'All inclusive',
    subtitle:
      'Monthly fee covers everything you need hassle free. Keep cool and focus on what matters to you.',
  },
  {
    icon: 'fas fa-phone-alt',
    title: 'Hospitality service',
    subtitle:
      '24/7 support. No more hidden prices. It is your workingplace, playground, relax room.',
  },
];

export const mapData = [
  {
    location: {
      y: 52.981229,
      x: -3.142859,
      address: 'Trefor Rocks',
      url: '/tour3d-trefor-rocks',
      title: 'Trefor Rocks, Llangollen, Carboniferous ',
    },
  },
  {
    location: {
      y: 54.490867,
      x: -0.615802,
      address: 'Whitby',
      url: '/tour3d-whitby',
      title: 'Whitby, NE England, Jurassic',
    },
  },
  {
    location: {
      y: 54.117917,
      x: -0.082608,
      address: 'Flamborough Head',
      url: '/tour3d-flamborough',
      title: 'NE England, Cretaceous',
    },
  },
  {
    location: {
      y: 54.558108,
      x: -0.781888,
      address: 'Helmshore Textile Museum',
      url: '/tour3d-htmuseum',
      title: 'Helmshore Textile Museum',
    },
  },
  {
    location: {
      y: 54.558108,
      x: -0.781888,
      address: 'Lower Ormerods',
      url: '/tour3d-ormerods',
      title: 'Lower Ormerods, ',
    },
  },
];
