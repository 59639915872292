/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import VirtualFieldTrips from 'views/VirtualFieldTrips';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';

export const Head = () => {
  <SEO description="Run rich virtual fieldtrips in VRGS." />;
};

const VirtualFieldTripsPage = () => {
  return <WithLayout component={VirtualFieldTrips} layout={Main} />;
};

export default VirtualFieldTripsPage;
